import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

api.interceptors.request.use(async (config) => {
    // get pocketbase_auth from local storage
    const pocketbase_auth = localStorage.getItem("pocketbase_auth");
    if(pocketbase_auth) {
        const json_auth = JSON.parse(pocketbase_auth);
        config.headers["Authorization"] = json_auth.token;
    }
    // console.log(pocketbase_auth);
    return config;
});

api.interceptors.response.use(async (response) => {
    return response.data;
},
    async (error) => {
        // console.error(error)
        return Promise.reject(error);
    }
);


const API_QUERY_KEY = Object.freeze({
    CHAT_ROOMS: 'chat_rooms',
    CHAT_HISTORY: 'chat_history',
    CHAT_PHOTO: 'chat_photo',
    MY_INFO: 'my_info',
});

export {
    API_QUERY_KEY,
}

export default api