import React, { lazy, Suspense } from 'react';
// import logo from './logo.svg';
// import './App.css';
import './csss/app.scss';
import '@mantine/core/styles.css';
import { Text } from "@mantine/core";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from "./context/AuthContext";
// import pb from "./pocket";
import AppLayout from "./component/AppLayout";
import { useLocation } from "react-router-dom";
const PageHome = lazy(() => import('./page/home'));
const PageLogin = lazy(() => import('./page/login'));
const PageChat = lazy(() => import('./page/chat'));

function App() {

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter >
  );
}

function AppRoutes() {
  const { authData } = useAuthContext();
  const location = useLocation();
  return (
    <Routes>
      {
        authData?.isValid ? (
          <React.Fragment>
            <Route element={<AppLayout />} >
              <Route path="/chat/:id" key={location.pathname} element={<Suspense fallback={<LazyComponentFallback />}><PageChat /></Suspense>} />
              <Route path="/" element={<Suspense fallback={<LazyComponentFallback />}><PageHome /></Suspense>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route path="/login" element={<Suspense fallback={<LazyComponentFallback />}><PageLogin /></Suspense>} />
            <Route path="*" element={<Navigate to="/login" />} />
          </React.Fragment>
        )
      }
    </Routes>
  )
}

function LazyComponentFallback() {
  return (<Text align="center" mt="sm">Loading...</Text>)
}
export default App;
