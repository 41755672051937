import { useEffect } from "react";
import { AppShell, Burger, Group, Skeleton, Button, Text, Divider, Stack, Box, Flex, Badge } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
// import { MantineLogo } from '@mantinex/mantine-logo';
import { Outlet } from "react-router-dom";
import pb from "../pocket";
import { useAuthContext } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import api, { API_QUERY_KEY } from "../utils/api";
import { NavLink } from "@mantine/core";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function AppLayout() {
  const [opened, { toggle }] = useDisclosure();
  const { logout, authData, setMyBotData } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, isError, data: chatRooms, error, refetch, status } = useQuery({
    queryKey: [API_QUERY_KEY.CHAT_ROOMS],
    queryFn: async () => {
      const data = await pb.collection('chat_room').getFullList();
      return data;
    },
    refetchInterval: 60 * 1000, // 1 minute interval for refetch data from server 
    // refetchOnWindowFocus: false
  });

  const { data: myInfo } = useQuery({
    queryKey: [API_QUERY_KEY.MY_INFO],
    queryFn: async () => {
      const data = await api.get('/chat/my-info');
      return data;
    },
    // refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (myInfo) {
      setMyBotData(myInfo);
    }
  }, [myInfo]);

  async function handleSelectRoom(roomId) {
    // console.log(roomId);
    toggle();
    navigate(`/chat/${roomId}`);
  }

  async function handleLogout() {
    await logout();
  }
  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding={0}
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          {/* <MantineLogo size={30} /> */}
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <Text mb="sm" fw="bold">Welcome, {authData.model.username}</Text>
        <Box mb="sm">
          <NavLink key={`home`}
            // to={`/chat/${room.id}`}
            onClick={() => navigate(`/`)}
            label={`Home`}
            variant="light"
            active={location.pathname === `/`}
          />

          <Flex align="center">
            <Box me="xs">
              <Text ms="xs" fz="xs" c="dimmed" my="xs">Chat rooms</Text>
            </Box>
            <Divider orientation="horizontal" style={{ flex: '1 auto' }} />
          </Flex>
          {
            chatRooms?.map((room, index) => (
              <NavLink key={room.id}
                // to={`/chat/${room.id}`}
                onClick={() => handleSelectRoom(room.id)}
                label={room.room_name}
                variant="light"
                active={location.pathname === `/chat/${room.id}`}
                rightSection={
                  room?.have_new_message && (
                    <Badge size="xs" color="red" circle>
                      n
                    </Badge>
                  )
                }
              />
            ))
          }
        </Box>
        <Button color="red" onClick={handleLogout}>
          Logout
        </Button>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}