import React, { createContext, useContext, useCallback, useEffect, useState } from 'react';
import pb from "../pocket";
import { useQuery, useQueryClient } from '@tanstack/react-query';
export const AuthContext = createContext();

function AuthContextProvider(props) {
    const queryClient = useQueryClient();
    const [authData, setAuthData] = useState(null);
    const [myBotData, setMyBotData] = useState(null);
    useEffect(() => {
        if (pb?.authStore?.isValid) {
            setAuthData(pb.authStore);
        }
        //   console.log(pb.authStore);
    }, [])

    async function login(data) {
        try {
            const auth = await pb.collection('users').authWithPassword(data.username, data.password);
            console.log(auth);
            // after the above you can also access the auth data from the authStore
            // console.log(pb.authStore.isValid);
            // console.log(pb.authStore.token);
            // console.log(pb.authStore.model.id);
            setAuthData(pb.authStore)
            return auth;
        } catch (error) {
            return { error: error.message, message: "Login failed" }
        }
    }

    async function logout() {
        pb.authStore.clear();
        setAuthData(null);
    }

    const value = { logout, login, authData, myBotData, setMyBotData };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

function useAuthContext() {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthContextProvider');
    }
    return context;
}

export { useAuthContext }

export default AuthContextProvider;